@import "../../../svg/sp_luxury";

.header-fixed {
    padding-top: 57px;
    .sortBox {
        top: 57px;
    }
    // 선배포를 위한 추가 마크업 배포후 해당 scss파일로 이동 필요
    .search_keyword_swiper li a b {
        color: $color-11st-red;
    }
}
.b-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 31;
    background-color: $color-gray-14;
}
.header-relative {
    .b-fixed {
        position: relative;
    }
}
.c-gnb {
    position: relative;
    height: 56px;
    background-color: $color-gray-14;
    color: $color-gray-01;
    border-bottom: 1px solid $color-gray-10;

    .c-n-search-bar {
        margin: -6px 0 -5px 36px;
    }

    &__menu {
        overflow: hidden;
        position: relative;
        height: 56px;
        .c-gnb__search-fake-input {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
        }
        &--visible {
            overflow: visible;
            .c-gnb {
                &__service-title,
                &__search-box {
                    display: inline-block;
                    width: -webkit-fill-available;
                    vertical-align: top;
                }
                &__tooltip {
                    display: block;
                }
            }
        }
    }
    &__logo {
        @include size(56px 30px);
        position: absolute;
        left: 54px;
        top: 14px;
        display: block;
        margin: 0;
    }
    &__link {
        &-11st {
            position: absolute;
            display: block;
            margin: 2px 0;
            img {
                @include size(56px 24px);
                display: block;
            }
        }
        &-amazon {
            position: absolute;
            display: none;
            padding: 10px 0 4px 0;
            &::before {
                @include size(1px 28px);
                content: "";
                display: block;
                background-color: #222f3f;
                position: absolute;
                left: -11px;
                top: 2px;
            }
            img {
                @include size(66px 20px);
                display: block;
            }
        }
    }
    &__gohome {
        display: block;
        margin: 2px 0;
        img {
            @include size(56px 24px);
            display: block;
        }
    }
    &__service-title {
        color: $color-gray-01;
        font-size: $font-size-title;
        font-weight: bold;
        letter-spacing: 0;
        margin: 19px 88px 8px 117px;
        height: 22px;
        line-height: 22px;
        overflow: hidden;
        white-space: pre-line;
        a {
            color: $color-gray-01;
            &:hover,
            &:focus {
                color: $color-gray-01;
                text-decoration: none;
            }
        }
    }
    &__sidemenu {
        @include sprite-retina($sp_gnb2_sidemenu);
        display: block;
        text-indent: -99999px;
        position: absolute;
        top: 10px;
        left: 8px;
    }
    &__search-box {
        display: block;
        position: relative;
        margin: 10px 55px 0 114px;
        border-radius: 19px;
        border: 1px solid $color-gray-12;
        background-color: $color-gray-12;
        height: 34px;

        .c-gnb__search-input,
        input {
            display: block;
            padding: 0 10px;
            width: 100%;
            height: 34px;
            font-size: 12px;
            border-radius: 19px;
            letter-spacing: $body-spacing;
            background-color: transparent !important;
            border: 0;
            outline: none;
            &::placeholder {
                color: rgba(0, 0, 0, 0.4);
            }
        }
        .c-gnb__search-label {
            position: absolute;
            top: 50%;
            // 검색버튼 포함 중앙 포지셔닝
            left: calc(50% - 2px);
            transform: translate(-50%, -50%);
            img {
                // 사이즈 고정
                width: 120px;
                height: 28px;
                vertical-align: top;
            }
        }
    }
    &__search-icon {
        @include sprite-retina($sp_gnb2_search);
        position: absolute;
        top: 10px;
        right: 55px;
        overflow: hidden;
        white-space: nowrap;
        text-indent: -9999px;
    }
    &__cart {
        @include sprite-retina($sp_gnb2_cart);
        position: absolute;
        top: 10px;
        right: 16px;
        text-indent: -9999px;
        @import "../../../skpui/component/badge";
        .c-badge {
            top: -2px;
            left: initial;
            right: -3px;
            font-size: 11px;
            height: 16px;
            min-width: 16px;
            line-height: 16px;
            padding: 0 3px 0 2px;
        }
    }
    &__tooltip {
        display: none;
        position: absolute;
        top: calc(100% - 6px);
        right: 10px;
        width: -webkit-max-content;
        width: max-content;
        padding: 11px 14px;
        border-radius: 30px;
        background-color: rgba($color-gray-02, 0.9);
        text-indent: 0;
        opacity: 0;
        filter: drop-shadow(0 2px 4px 0px rgba(0, 0, 0, 0.08));
        transition: opacity 0.5s ease-in-out;

        // tail
        &:before {
            content: "";
            position: absolute;
            top: -5px;
            right: 18px;
            width: 15px;
            height: 6px;
            background-color: rgba($color-gray-02, 0.9);
            clip-path: polygon(6px 6px, 9.7px 1.06667px, 9.7px 1.06667px, 9.8312px 0.922669253px, 9.9816px 0.810668624px, 10.1464px 0.730668131px, 10.3208px 0.682667792px, 10.5px 0.666667625px, 10.6792px 0.682667648px, 10.8536px 0.730667879px, 11.0184px 0.810668336px, 11.1688px 0.922669037px, 11.3px 1.06667px, 15px 6px, 6px 6px);
        }

        &--show {
            opacity: 1;
            animation-name: fade-out;
            animation-delay: 5s;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
        }
    }
    &__tooltip-text {
        display: block;
        overflow: hidden;
        max-width: 315px;
        height: 14px;
        font-weight: 700;
        font-size: 13px;
        color: $color-gray-14;
        line-height: 14px;
        @media screen and (max-width: 374px) {
            max-width: calc(100vw - 60px);
        }

        &--ellipsis {
            max-width: 180px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &__search-button {
        position: relative;
        display: flex;
        align-items: center;
        width: calc(100% + 30px); // wrapper인 search-box의 padding-right를 더해준다
        height: 100%;
        padding-right: 40px;
        padding-left: 10px;
        border: 0;
        color: rgba(0, 0, 0, 0.4);
        font-size: 12px;
        background-color: transparent;
    }
    &__search-text {
        overflow: hidden;
        display: block;
        white-space: nowrap;
    }

    // white 11번가 로고
    .logo-11st {
        overflow: hidden;
        display: block;
        width: 37px;
        height: 16px;
        color: transparent;
        font-size: 10px;
        background: url("/MW/img/svg/logo/logo-11st-white.svg") no-repeat 0 0;
        background-size: 100%;
    }
    // black 11번가 로고
    .logo-11st-dark {
        overflow: hidden;
        display: block;
        width: 37px;
        height: 16px;
        color: transparent;
        font-size: 10px;
        background: url("/MW/img/svg/logo/logo-11st.svg") no-repeat 0 0;
        background-size: 100%;
    }
    // 패션 추천 로고
    .logo-ootd {
        overflow: hidden;
        display: block;
        width: 74px;
        height: 23px;
        color: transparent;
        font-size: 1px;
        background: url("/MW/img/svg/logo/logo-ootd.svg") no-repeat 0 0;
        background-size: 100%;
        box-sizing: border-box;
    }
    // 마트플러스 로고
    .logo-mart {
        overflow: hidden;
        display: block;
        width: 80px;
        height: 16px;
        color: transparent;
        font-size: 1px;
        background: url("/MW/img/svg/logo/logo-martplus.svg") no-repeat 0 0;
        background-size: 100%;
        box-sizing: border-box;
    }

    &--amazon {
        .c-gnb__logo {
            @include size(126px 30px);
            top: 12px;
        }
        .c-gnb__link {
            &-11st {
                width: 37px;
                padding: 8px 0 10px 0;
                margin: 0;
                animation: none;
                img {
                    transition: none;
                    @include size(37px 16px);
                }
            }
            &-amazon {
                display: block;
                left: 58px;
                animation: none;
                img {
                    transition: none;
                }
            }
        }
        .c-gnb__search-box {
            margin-left: 148px;
            transition: all 0.4s;
            .c-gnb__search-icon {
                // @include sprite-retina($sp_gnb2_search-amazon);
                background-image: url("/MW/img/common/ic_gnb_search_amazon.gif");
                background-position: 0 0;
                width: 28px;
                height: 28px;
                background-size: 28px 28px;
                top: 3px;
                right: 3px;
            }
        }
        &.c-gnb--search,
        &.c-gnb--app.c-gnb--search {
            height: 108px;

            &::after {
                display: none;
            }
            .c-gnb__menu {
                height: 108px;
            }
            .c-gnb__search-box {
                margin: 56px 16px 0;
                height: 38px;
                border-radius: 20px;
                border: 1px solid $color-gray-12;
                background-color: $color-gray-12;
                transition: initial;

                .c-gnb__search-input,
                input {
                    height: 38px;
                    padding: 0 16px;
                    font-size: $font-size-body1;
                }
                .del {
                    height: 24px;
                    top: 7px;
                    right: 8px;
                }
            }
        }
    }
    &--main {
        border-bottom: 0;
        .c-gnb__menu {
            display: flex;
        }
        .c-gnb__logo {
            position: relative;
            flex-shrink: 0;
            top: auto;
            left: auto;
            margin: 14px 0 0 14px;
        }
        .c-gnb__link-11st {
            animation: fade-in 0.5s;
            img {
                transition: all 0.4s;
            }
        }
        .c-gnb__link-amazon {
            animation: fade-in 0.5s;
            img {
                transition: all 0.4s;
            }
        }
        .c-gnb__search-box {
            position: relative;
            flex: 1;
            margin: 10px 0 0 10px;
            padding-right: 30px;
            transition: all 0.4s;
            .c-gnb__search-icon {
                @include sprite-retina($sp_gnb2_search-red);
                border: 0;
                background-color: transparent;
                top: 5px;
                right: 6px;
            }
        }
        .c-gnb__cart {
            display: block;
            flex-shrink: 0;
            position: relative;
            top: auto;
            right: auto;
            margin: 10px 16px 0 8px;
        }

        .c-portal-button {
            --portalBgColor: linear-gradient(135deg, #ff5a2e 0%, #ff0038 51.96%, #ff00ef 100%);
            --portalOutlineColor: #{$color-gray-10};
            --portalArrowStartColor: #{$color-gray-04};
            --portalArrowEndColor: #{$color-gray-14};
        }

        &.c-gnb--amazon {
            .c-gnb__logo {
                margin-top: 12px;
            }
            .c-gnb__search-box {
                margin-left: 8px;
                .c-gnb__search-icon {
                    // @include sprite-retina($sp_gnb2_search-amazon);
                    background-image: url("/MW/img/common/ic_gnb_search_amazon.gif");
                    background-position: 0 0;
                    width: 28px;
                    height: 28px;
                    background-size: 28px 28px;
                    top: 3px;
                    right: 3px;
                }
            }
        }
        &.c-gnb--11st {
            .c-gnb__search-box {
                .c-gnb__search-icon {
                    // @include sprite-retina($sp_gnb2_search-11st);
                    background-image: url("/MW/img/common/ic_gnb_search_11st.gif");
                    background-position: 0 0;
                    width: 28px;
                    height: 28px;
                    background-size: 28px 28px;
                    top: 3px;
                    right: 3px;
                }
            }
        }
    }
    &--search {
        border-bottom: 0;
        height: 57px;
        &:after {
            content: "";
            display: block;
            position: relative;
            bottom: 1px;
            height: 2px;
            background: $color-11st-gradient;
        }
        .c-gnb__logo {
            left: 54px;
        }
        .c-gnb__search-box {
            height: 34px;
            margin: 12px 55px 0 106px;
            padding-right: 30px;
            border: 0;
            background-color: transparent;

            .c-gnb__search-input,
            input {
                display: block;
                width: 100%;
                color: $color-gray-01;
                font-size: $font-size-title;
                font-weight: bold;
                letter-spacing: 0;
                border: 0;
                text-align: left;
            }
            button.c-gnb__search-input {
                overflow: hidden;
                white-space: nowrap;
            }
            .del {
                @include sprite-retina($sp_gnb2_cancel);
                display: block;
                position: absolute;
                right: 3px;
                top: 5px;
                background-color: transparent;
                text-indent: -99999px;
                border: 0;
            }
        }
    }
    &--11st {
        &-amazon {
            animation: fade-out 0.5s;
        }
    }
    &--app {
        .c-gnb__logo {
            left: 14px;
        }
        .c-gnb__service-title {
            margin-left: 75px;
        }
        .c-gnb__sidemenu {
            display: none;
        }
        &.c-gnb--search {
            .c-gnb__search-box {
                margin: 12px 55px 0 70px;
            }
        }
        &.c-gnb--detail-brand {
            .c-gnb__sidemenu {
                & + .c-gnb__logo {
                    margin-left: 0;
                }
            }
        }
    }

    // 우아럭스 GNB
    // TODO: MPSR-149683 배포 후 정리 필요
    &--luxury {
        border-bottom: 1px solid #eee;

        .logo-luxury {
            display: block;
            height: 28px;
            padding-top: 6px;
            color: $color-luxury;
            box-sizing: border-box;

            img {
                display: block;
                height: 16px;
            }
        }

        .c-gnb {
            &__menu {
                height: auto;
                padding: 14px 16px;
            }
            &__logo {
                position: static;
                display: flex;
                align-items: center;
                width: auto;
                height: 28px;
            }
            &__link + .c-gnb__link {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid #ccc;
            }
            &__cart {
                @include get-sprite-luxury("ic-gnb-cart", 36);

                right: 10px;

                .c-badge {
                    padding-right: 4px;
                    padding-left: 4px;
                    font-family: $font-family3;
                    font-weight: bold;
                    background-color: $color-luxury;
                }
            }
            &__search-icon {
                @include get-sprite-luxury("ic-gnb-search", 36);

                right: 53px;
            }
            &__back {
                @include get-sprite-luxury("ic-gnb-back", 36);

                position: absolute;
                top: 10px;
                left: 8px;
                border: 0;
                background-color: transparent;

                & + .c-gnb__logo {
                    margin-left: 36px;
                }
            }
        }
    }
    &--luxury-combi {
        .c-n-search-bar {
            margin-right: 36px;
        }
    }

    // PDP 브랜드픽 GNB
    &--detail-brand {
        border-bottom: 1px solid $color-gray-11;
        .logo-11st {
            overflow: hidden;
            display: block;
            width: 37px;
            height: 16px;
            color: transparent;
            font-size: 10px;
            background: url("/MW/img/svg/logo/11st.svg") no-repeat 0 0;
            background-size: 100% auto;
        }
        .logo-brand {
            @include text-ellipsis;
            display: block;
            height: 28px;
            padding: 3px 0;
            font-size: 18px;
            line-height: 24px;
            color: $color-gray-01;
            box-sizing: border-box;

            img {
                display: block;
                height: 28px;
                margin: -3px 0;
            }
        }
        .c-gnb {
            &__menu {
                height: auto;
                padding: 14px 96px 14px 16px;
            }
            &__logo {
                position: static;
                display: flex;
                align-items: center;
                width: auto;
                height: 28px;
            }
            &__link + .c-gnb__link {
                display: block;
                overflow: hidden;
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid $color-gray-03;
            }
            &__back {
                @include get-sprite-luxury("ic-gnb-back", 36);

                position: absolute;
                top: 10px;
                left: 8px;
                border: 0;
                background-color: transparent;

                & + .c-gnb__logo {
                    margin-left: 36px;
                }
            }

            &__sidemenu {
                & + .c-gnb__logo {
                    margin-left: 36px;
                }
            }
        }
    }

    // 패션추천 GNB
    // TODO: MPSR-149683 배포 후 정리 필요
    &--ootd {
        border-bottom: 0;
        transition: all 0.15s cubic-bezier(0.42, 0, 1, 1);
        backdrop-filter: blur(0);

        .c-gnb {
            &__menu {
                height: auto;
                padding: 14px 16px;
            }
            &__logo {
                position: static;
                display: flex;
                align-items: stretch;
                width: auto;
                height: 28px;
            }
            &__link {
                display: flex;
                align-items: center;
            }
            &__link + .c-gnb__link {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid $color-gray-03;
            }
            &__cart {
                background: url("/MW/img/ootd/ic_gnb_cart.svg") no-repeat 0 0;
                background-size: 100%;

                .c-badge {
                    padding-right: 4px;
                    padding-left: 4px;
                    font-family: $font-family3;
                    font-weight: bold;
                }
            }
            &__search-icon {
                @include get-sprite-luxury("ic-gnb-search", 36, "mask");
                right: 60px;
                background: $color-gray-03;
            }
            &__back {
                @include get-sprite-luxury("ic-gnb-back", 36);

                position: absolute;
                top: 10px;
                left: 8px;
                border: 0;
                background-color: transparent;

                & + .c-gnb__logo {
                    margin-left: 36px;
                }
            }
        }
        .logo-11st {
            background-image: url("/MW/img/svg/logo/11st.svg");
        }
    }
    &--ootd-dark {
        background-color: transparent;
        border-bottom-color: transparent;

        .logo-11st {
            background-image: url("/MW/img/svg/logo/logo-11st-white.svg");
        }
        .logo-ootd {
            background-image: url("/MW/img/svg/logo/logo-ootd-white.svg");
        }
        .c-gnb__cart {
            background-image: url("/MW/img/svg/ooah-pick/ic-cart.svg");
        }
        .c-gnb__search-icon {
            background: $color-gray-14;
        }
        .c-gnb__link + .c-gnb__link {
            border-color: rgba(255, 255, 255, 0.3);
        }
    }
    &--ootd-blur {
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(120px);
    }

    // AiFeed GNB
    &--aifeed {
        border-color: $color-gray-11;
        padding-left: 8px;

        .logo-aifeed {
            display: flex;
            align-items: center;
            height: 100%;
        }

        .c-gnb {
            &__menu {
                display: flex;
                align-items: center;
            }

            &__sidemenu {
                order: -1;
                position: static;
                margin-right: 2px;
            }

            &__logo {
                display: flex;
                align-items: center;
                position: static;
                overflow: hidden;
                width: auto;
                height: 28px;
                margin-left: 8px;
            }

            &__link {
                & + .c-gnb__link {
                    height: 100%;
                    margin-left: 10px;
                    padding-left: 10px;
                    border-left: 1px solid $color-gray-09;
                }
            }

            &__history-link {
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: auto;
                padding: 0 16px;
                font-size: 14px;
                color: $color-gray-01;
            }
        }
    }
}

// ** 2025 GNB 개편
.c-gnb--home {
    height: auto;
    padding-top: env(safe-area-inset-top);

    // 안드로이드 웹뷰 env(safe-area-inset-top) 적용되지 않음, 상단바 영역 (24px) 확보
    @at-root .webview-aos & {
        padding-top: 24px;
    }

    .c-gnb__menu {
        display: flex;
        align-items: center;
        height: auto;
        min-height: 55px;
        padding: 0 16px;
    }

    .c-gnb__logo {
        position: static;
        display: flex;
        align-items: stretch;
        flex-shrink: 0;
        width: auto;
        height: 28px;
    }

    .c-gnb__cart {
        right: 16px;
        background: url("/MW/img/svg/ooah-pick/ic-cart.svg") no-repeat 0 0;
        .c-badge {
            font-weight: 700;
            font-family: $font-family3;
            background: $color-gray-14;
        }
    }
    .c-gnb__search-icon {
        @include get-sprite-luxury("ic-gnb-search", 36, "mask");
        right: 56px;
        background: $color-gray-14;
    }
    .c-gnb__link {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }
    .c-gnb__link + .c-gnb__link {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
    }

    .logo-11st {
        background-image: url("/MW/img/svg/logo/logo-11st-white.svg");
    }
    .logo-ootd {
        background-image: url("/MW/img/svg/logo/logo-ootd-white.svg");
    }
    .logo-mart {
        background: $color-gray-14;
        mask: url("/MW/img/svg/logo/logo-martplus.svg") no-repeat 0 0 / 100%;
    }
    .logo-luxury img {
        filter: brightness(0) invert(1);
    }

    .c-portal-button {
        --portalBgColor: #{$color-gray-14};
        --portalOutlineColor: #{$color-gray-14};
        --portalArrowStartColor: #{$color-gray-14};
        --portalArrowEndColor: #{$color-gray-14};
    }

    &.c-gnb {
        &--luxury {
            border-bottom: 1px solid $color-luxury;
            background-color: $color-luxury;

            .c-badge {
                color: $color-luxury;
            }

            .c-portal-button {
                --portalArrowEndColor: #{$color-luxury};
            }
        }

        &--ootd {
            border-bottom: 1px solid $color-11st-red;
            background-color: $color-11st-red;
            backdrop-filter: none;

            .c-badge {
                color: $color-11st-red;
            }

            .c-portal-button {
                --portalArrowEndColor: #{$color-11st-red};
            }
        }

        &--mart {
            border-image: linear-gradient(90deg, #ff9000 0%, #ff6a00 100%);
            border-image-slice: 1;
            background: linear-gradient(90deg, #ff9000 0%, #ff6a00 100%);

            .c-badge {
                color: $color-mart;
            }

            .c-portal-button {
                --portalArrowEndColor: #{$color-mart};
            }
        }
    }
}

.c-gnb--sub {
    height: auto;
    border-bottom: 1px solid $color-gray-10;

    .c-gnb__menu {
        display: flex;
        align-items: center;
        height: auto;
        min-height: 55px;
        padding: 0 8px;
    }

    .c-gnb__logo {
        position: static;
        display: flex;
        align-items: stretch;
        width: auto;
        min-width: 0;
        height: 28px;
        &:not(:first-child) {
            margin-left: 8px;
        }
    }

    .c-gnb__link {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }

    .c-gnb__link + .c-gnb__link {
        flex-shrink: 1;
        min-width: 0;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid $color-gray-09;
    }

    .c-gnb__sidemenu {
        position: static;
        flex-shrink: 0;
    }

    .c-gnb__cart {
        display: block;
        position: relative;
        top: auto;
        right: auto;
        flex-shrink: 0;
        margin: 0 8px 0 4px;
        background: url("/MW/img/ootd/ic_gnb_cart.svg") no-repeat 0 0;
        background-size: 100%;

        .c-badge {
            padding-right: 4px;
            padding-left: 4px;
            background-color: var(--gnbKeyColor, $color-11st-red);
            font-weight: bold;
            font-family: $font-family3;
        }
    }

    .c-gnb__search-icon {
        @include get-sprite-luxury("ic-gnb-search", 36, "mask");
        position: static;
        flex-shrink: 0;
        margin-left: auto;
        background: $color-gray-03;
    }

    .c-gnb__back {
        @include get-sprite-luxury("ic-gnb-back", 36);
        position: static;
        flex-shrink: 0;
        border: 0;
        background-color: transparent;
    }

    .logo-11st {
        background-image: url("/MW/img/svg/logo/11st.svg");
    }

    .logo-brand {
        @include text-ellipsis;
        display: block;
        font-size: 18px;
        line-height: normal;
        color: $color-gray-01;

        img {
            display: block;
            height: 28px;
        }
    }

    .c-portal-button {
        --portalBgColor: transparent;
        --portalOutlineColor: #{$color-gray-10};
        --portalArrowStartColor: #{$color-gray-04};
        --portalArrowEndColor: #{$color-gray-04};
        height: 28px;
        margin-right: auto;
    }

    &.c-gnb {
        &--luxury {
            .logo-11st {
                background-image: url("/MW/img/svg/logo/logo-11st.svg");
            }

            .c-badge {
                background-color: $color-luxury;
            }

            @media screen and (max-width: 359px) {
                .c-portal-button {
                    width: auto;
                    margin-left: 4px;
                }

                .c-gnb__cart {
                    margin: 0;
                }
            }
        }

        &--ooah-pick {
            .c-gnb__back {
                margin-left: 0;
            }

            .c-gnb__link {
                flex-shrink: 0;
                margin-left: 0;
                padding-left: 0;
                border-left: 0;
            }

            .c-gnb__cart {
                right: 0;
                margin: 0;
            }

            .c-portal-button {
                --portalArrowStartColor: #{$color-gray-14};
                --portalArrowEndColor: #{$color-gray-14};
            }
        }

        &--ootd {
            backdrop-filter: none;
        }

        &--mart {
            .c-badge {
                background-color: $color-mart;
            }
        }
    }
}

.c-portal-button {
    flex-shrink: 0;
    width: 34px;
    height: 100%;
    border: 0;
    background: none;
    &__icon {
        display: block;
        overflow: hidden;
        position: relative;
        width: 18px;
        height: 18px;
        margin: auto;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px var(--portalOutlineColor);
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border-radius: 50%;
            background: var(--portalBgColor);
            opacity: 0;
            animation: portalButtonGradient 5s ease-in-out infinite;
        }
    }
    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 8px;
        height: 6px;
        margin: auto;
        color: var(--portalArrowStartColor);
        animation: portalButtonArrow 5s ease-in-out infinite;
    }

    & + .c-gnb__search-box {
        margin-left: 0;
    }

    @keyframes portalButtonGradient {
        0% {
            opacity: 0;
        }
        // 2s
        40% {
            opacity: 0;
        }
        // 2.5s
        50% {
            opacity: 1;
        }
        // 4.5s
        90% {
            opacity: 1;
        }
        // 5s
        100% {
            opacity: 0;
        }
    }
    @keyframes portalButtonArrow {
        0% {
            color: var(--portalArrowStartColor);
        }
        // 2s
        40% {
            color: var(--portalArrowStartColor);
        }
        // 2.5s
        50% {
            color: var(--portalArrowEndColor);
        }
        // 3s
        60% {
            transform: translateY(0px);
        }
        // 3.25s
        65% {
            transform: translateY(4px);
        }
        // 3.5s
        70% {
            transform: translateY(0px);
        }
        // 3.75s
        75% {
            transform: translateY(4px);
        }
        // 4s
        80% {
            transform: translateY(0px);
        }
        // 4.5s
        90% {
            color: var(--portalArrowEndColor);
        }
        // 5s
        100% {
            color: var(--portalArrowStartColor);
        }
    }
}

.c-portal {
    display: none;
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 102;
    width: 100%;
    padding-bottom: 4px;
    animation-duration: 0.2s;
    animation-fill-mode: backwards;
    transform: translate(-50%, 0);

    &--in {
        animation-name: portalSlideIn;
    }
    &--out {
        transform: translate(-50%, -100%);
        animation-name: portalSlideOut;
    }

    &__close {
        @include btn-close-x($size: 16.6px, $weight: 1.25px, $color: $color-gray-14, $rate: 2);
        display: block;
        position: relative;
        width: 32px;
        height: 32px;
        margin: -16px auto 0;
        border: 0;
        border-radius: 50%;
        background-color: $color-gray-03;
    }
    &__layer {
        display: flex;
        flex-direction: column;
        position: relative;
        max-height: calc(100vh - 50px);
        padding-top: env(safe-area-inset-top);
        padding-bottom: 24px;
        border-radius: 0 0 20px 20px;
        background: $color-gray-14;
        // 안드로이드 웹뷰 env(safe-area-inset-top) 적용되지 않음, 상단바 영역 (24px) 확보
        @at-root .webview-aos & {
            padding-top: 24px;
        }
    }
    &__inner {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    &__list {
        margin: 12px 16px 8px;
    }
    &__item {
        margin-top: 10px;
    }
    &__link {
        display: block;
        overflow: hidden;
        position: relative;
        height: 104px;
        border-radius: 16px;
        background-image: linear-gradient(90deg, var(--portalColor, $color-gray-01) 0%, var(--portalGradientColor, var(--portalColor, $color-gray-01)) 100%);
    }
    &__thumbnail {
        position: relative;
        height: 100%;
    }
    &__image {
        width: 160px;
        height: 100%;
        margin-left: auto;
        .c-lazyload:before {
            background-color: transparent;
        }
        .c-lazyloaded {
            height: 100%;
        }
    }
    &__video {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;

        &--out {
            video {
                transition: opacity 0.2s ease-out;
                opacity: 0;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(90deg, var(--portalColor, $color-gray-01) 0%, transparent 52.48%);
        }
        video {
            position: relative;
            height: 100%;
        }
        .c-lazyload {
            position: absolute;
            top: 0;
            right: 0;
        }
        .c-lazyloaded {
            width: 100%;
            height: 100%;
        }
    }
    &__info {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 16px 24px;
    }
    &__logo {
        display: inline-flex;
        align-items: center;
        position: relative;
        vertical-align: top;
    }
    &__logo-image {
        flex-shrink: 0;
        height: 36px;
        vertical-align: top;
    }
    &__logo-arrow {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-left: 8px;
    }
    &__desc {
        font-family: $font-family3;
        font-size: 13px;
        color: $color-gray-14;
        line-height: 15px;
    }

    @keyframes portalSlideIn {
        from {
            transform: translate(-50%, -100%);
        }

        to {
            transform: translate(-50%, 0);
        }
    }
    @keyframes portalSlideOut {
        from {
            transform: translate(-50%, 0);
        }

        to {
            transform: translate(-50%, -100%);
        }
    }
}

.c-headline--low {
    .c-headline__title {
        height: 56px;
        padding: 8px 16px;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

// 파트너 제휴 배너 노출 시
body {
    &.partner-banner {
        .header-fixed {
            padding-top: 0;
        }
        .header-fixed.header-fixed--banner {
            padding-top: 0;
        }
        .header-fixed.header-fixed--banner .c-banner-applink {
            position: relative;
        }
        .header-fixed.header-fixed--banner .b-fixed {
            position: relative;
            top: auto;
        }
        .header-fixed .b-fixed {
            position: relative;
        }
        &.is-scroll {
            .header-fixed {
                padding-top: 57px;
            }
            .header-fixed.header-fixed--banner {
                padding-top: 107px;
            }
            .header-fixed.header-fixed--banner .c-banner-applink {
                position: fixed;
            }
            .header-fixed.header-fixed--banner .b-fixed {
                position: fixed;
                top: 50px;
            }
            .header-fixed .b-fixed {
                position: fixed;
            }
        }
    }
}
