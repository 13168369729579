/* cts */
#cts,
.main {
    position: relative;
    margin: 0 0 10px;
}

body.grayType {
    background: $color-gray-11;
}
